.metadado {
    margin-bottom: 20px !important;
    
    .item {
        margin-bottom: 0px;
    }

    .valor {
        margin-bottom: 0px;
    }
}
