.lista-series {
    font-weight: 700;
    line-height: 20px;

    .cabecalho :global(.row) {
        background-color: #1351b4;
        color: white;
        height: 40px;
        display: flex;
        align-items: center;
    }

    .linha {
        display: flex;
        align-items: center;
        border: 1px solid #9090c1;

        .expansor {
            cursor: pointer;
        }
    
        .botao {
            margin-top: 2px;
            margin-bottom: 2px;
            margin-right: 3px;
        }
    
        .linha-botoes {
            display: flex;
            align-items: center;
            flex-direction: row-reverse;
        }
    }
}

